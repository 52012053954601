import * as React from 'react';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';

type Props = {
  powerDistributionUnit: PowerDistributionUnit;
  onClickEditButton?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onClickRemoveButton?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
};

export default function PowerDistributionUnitsTableItem({
  powerDistributionUnit,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <TableBodyRow>
      <TableBodyColumn className="font-bold text-white">
        {powerDistributionUnit.powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {powerDistributionUnit.powerDistributionUnitDocumentId}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnit.powerDistributionUnitCountryState}
      </TableBodyColumn>
      <TableBodyColumn>
        <EditButton onClick={onClickEditButton} />
        <RemoveButton onClick={onClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
