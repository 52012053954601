import classNames from 'classnames';

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

function PaginationButton({
  children,
  className,
  disabled,
  onClick,
  selected = false,
}: ButtonProps) {
  return (
    <button
      aria-current={selected ? 'page' : undefined}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        selected
          ? 'z-10 font-bold bg-primary border-primary text-gray-background'
          : 'font-normal border-gray-dark-b6 text-gray-dark-b6 hover:bg-primary hover:border-primary hover:text-gray-background',
        'relative inline-flex items-center border text-xs rounded focus:outline-none focus:bg-primary focus:border-primary focus:text-gray-background',
        className ? className : 'px-4 py-2'
      )}
    >
      {children}
    </button>
  );
}

type PaginationProps = {
  disableBefore?: boolean;
  disableNext?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
};

export default function Pagination({
  disableBefore,
  disableNext,
  onNextClick,
  onPreviousClick,
}: PaginationProps) {
  return (
    <nav
      className="relative flex items-center justify-end flex-1 py-3.5 px-5 gap-x-3.5"
      aria-label="Pagination"
    >
      <PaginationButton
        disabled={disableBefore}
        onClick={onPreviousClick}
        className="px-2 py-2"
      >
        Anterior
      </PaginationButton>
      <PaginationButton
        disabled={disableNext}
        onClick={onNextClick}
        className="px-2 py-2"
      >
        Próxima
      </PaginationButton>
    </nav>
  );
}
