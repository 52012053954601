import React, { useCallback, useEffect, useState, createContext } from 'react';
import { MdCheckCircle, MdClose, MdInfoOutline } from 'react-icons/md';
import { Button } from 'ui';

export type ToastProps = {
  text: string;
  title: string;
  variant: Variant;
};

const ToastContext = createContext<{ addToast: (toast: ToastProps) => void }>({
  addToast: () => {},
});

export default ToastContext;

const DEFAULT_HIDE_DELAY = 3000;

export type Variant = 'danger' | 'primary';

const variants: Record<Variant, { icon: React.ReactNode }> = {
  danger: {
    icon: <MdInfoOutline className="text-red-a1 text-xl" />,
  },
  primary: {
    icon: <MdCheckCircle className="text-primary text-xl" />,
  },
};
export function ToastContextProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        DEFAULT_HIDE_DELAY
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast: ToastProps) {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="absolute top-10 right-10 w-auto h-auto text-red-600 z-50">
        {toasts.map(({ title, text, variant }, k) => (
          <div
            className="flex items-center justify-center mb-3"
            key={Date.now() + Math.random()}
          >
            <div className="bg-gray-background rounded-lg shadow-xl p-3 max-w-md flex">
              <div className="mr-2">{variants[variant].icon}</div>
              <div>
                <h4 className="text-gray-dark-b6 font-medium text-md">{title}</h4>
                <p className="text-gray-dark300 text-sm ml-0">{text}</p>
              </div>
              <div className="mr-2">
                <Button
                  variant="icon"
                  className="bg-gray-dark400 w-auto pr-1 pl-1 pb-1 pt-1 m-0"
                  rounded="circular"
                  onClick={() => setToasts(toasts.filter((_, i) => i !== k))}
                >
                  <MdClose className="text-gray-dark-6b" />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
}
