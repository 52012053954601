import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import UserFormFields, {
  FormFields,
} from 'dashboard/components/internal-users/form/UserFormFields';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { setFormError } from 'utils/form';
import {
  InternalUserCreated,
  InternalUserCreateMutationVariables,
  INTERNAL_USER_CREATE_MUTATION,
} from 'dashboard/graphql/internal-users/internalUserCreateMutation';
import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';

const DASHBOARD_INTERNAL_USERS_ROUTE = '/dashboard/internal-users';

const BREADCRUMB_PAGES: Page[] = [
  { name: 'Gestão de usuários', route: null, current: false },
  { name: 'Usuário interno', route: DASHBOARD_INTERNAL_USERS_ROUTE, current: false },
  {
    name: 'Novo usuário interno',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo usuário interno';
const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Successfully saved!',
  variant: 'primary',
  text: 'Anyone with a link can now view this file.',
};

export default function CreateInternalUsersPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [internalUserCreateMutation, { loading }] = useMutation<
    InternalUserCreated,
    InternalUserCreateMutationVariables
  >(INTERNAL_USER_CREATE_MUTATION, {
    onError(error: ApolloError) {
      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        }
        // t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_INTERNAL_USERS_ROUTE);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (internalUserCreateInput) => {
    internalUserCreateMutation({
      variables: {
        internalUserCreateInput,
      },
    });
  };

  useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [loading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={loading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <UserFormFields errors={errors} register={register} />
      </Dashboard>
    </form>
  );
}
