import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function TableBodyRow({ children, className }: Props) {
  return (
    <tr
      className={classNames('text-sm font-medium hover:bg-gray-dark-11', className)}
    >
      {children}
    </tr>
  );
}
