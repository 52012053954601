import { useHistory } from 'react-router-dom';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import UsersTable from 'dashboard/components/internal-users/table/UsersTable';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { ChangeEvent, useEffect, useState } from 'react';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { InternalUser } from 'dashboard/models/internal-user';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { INTERNAL_USER_LIST_QUERY } from 'dashboard/graphql/internal-users/internalUserListQuery';
import {
  INTERNAL_USER_TOGGLE_ACCESS_STATUS_MUTATION,
  InternalUserToggleAccessStatusVariables,
} from 'dashboard/graphql/internal-users/internalUserToggleAccessStatusMutation';
import {
  INTERNAL_USER_DELETE_MUTATION,
  InternalUserDeleteMutationVariables,
} from 'dashboard/graphql/internal-users/internalUserDeleteMutation';
import {
  INTERNAL_USER_UPDATE_MUTATION,
  InternalUserUpdateMutationVariables,
} from 'dashboard/graphql/internal-users/internalUserUpdateMutation';

type ListState = {
  users: InternalUser[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_INTERNAL_USERS_CREATE_ROUTE = '/dashboard/internal-users/create';
const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Usuários',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  text: 'Usuário atualizado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};
const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar o usuário',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function ListInternalUsersPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [search, setSearch] = useState('');
  const [deleteId, setDeleteId] = useState<number>();
  const [listState, setListState] = useState<ListState>({
    users: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [internalUserListQuery, { data, loading, error, refetch }] = useLazyQuery(
    INTERNAL_USER_LIST_QUERY,
    {
      variables: {},
      notifyOnNetworkStatusChange: true,
    }
  );

  const [internalUserDeleteMutation, { loading: internalUserDeleteLoading }] =
    useMutation<InternalUserDeleteMutationVariables>(INTERNAL_USER_DELETE_MUTATION, {
      onError() {
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
      },
    });

  const [internalUserUpdateMutation, { loading: internalUserUpdateLoading }] =
    useMutation<InternalUserUpdateMutationVariables>(INTERNAL_USER_UPDATE_MUTATION, {
      onError() {
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
      },
    });

  const [
    internalUserToggleStatusMutation,
    { loading: internalUserToggleStatusLoading },
  ] = useMutation<InternalUserToggleAccessStatusVariables>(
    INTERNAL_USER_TOGGLE_ACCESS_STATUS_MUTATION,
    {
      onError() {
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
      },
    }
  );

  useEffect(() => {
    internalUserListQuery();
  }, [internalUserListQuery]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  useEffect(() => {
    if (
      loading ||
      internalUserUpdateLoading ||
      internalUserToggleStatusLoading ||
      internalUserDeleteLoading
    ) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        users: data.internalUsers.entries,
        hasNextPage: !!data.internalUsers.afterCursor,
        hasPreviousPage: !!data.internalUsers.beforeCursor,
      });
    }

    closeLoading();
  }, [
    addToast,
    closeLoading,
    data,
    loading,
    showLoading,
    internalUserToggleStatusLoading,
    internalUserUpdateLoading,
    internalUserDeleteLoading,
  ]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.internalUsers.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.internalUsers.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) => {
    push(`/dashboard/internal-users/${id}/edit`);
  };

  const onClickRemoveButton = (id: number) => {
    setDeleteId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    internalUserDeleteMutation({ variables: { id: deleteId } });
    closeConfirm();
  };

  const onCancelDelete = () => {
    closeConfirm();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const handleToggleIsAdmin = (id: number, userIsAdmin: boolean) => {
    internalUserUpdateMutation({
      variables: {
        internalUserUpdateInput: { id, userIsAdmin },
      },
    });
  };

  const handleToggleBlockedStatus = (id: number) => {
    internalUserToggleStatusMutation({
      variables: { id },
    });
  };

  function onClickAddButton() {
    push(DASHBOARD_INTERNAL_USERS_CREATE_ROUTE);
  }

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="Usuário Interno"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Usuários Internos" />
      }
    >
      <div className="rounded-lg bg-gray-background">
        {listState.users && (
          <>
            <UsersTable
              users={listState.users}
              onToggleAdmin={handleToggleIsAdmin}
              onToggleBlockedStatus={handleToggleBlockedStatus}
              disableAdminToggle={internalUserUpdateLoading}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage}
              disableBefore={!listState.hasPreviousPage}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação de conta!"
          text="Tem certeza que deseja desativar essa conta? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
