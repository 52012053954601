import ToggleButton from 'ui/components/button/ToggleButton';
import { InternalUser, UserId } from 'dashboard/models/internal-user';
import { TableBodyColumn, TableBodyRow } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';

type Props = {
  user: InternalUser;
  disableAdminToggle: boolean;
  onClickEditButton?: (id: number) => void;
  onClickRemoveButton?: (id: number) => void;
  onToggleBlock: (userId: UserId, value: boolean) => void;
  onToggleAdmin: (userId: UserId, value: boolean) => void;
};

export default function UsersTableItem({
  user: { id, userName, userEmail, userIsAdmin, userAccessBlockedAt },
  disableAdminToggle,
  onClickEditButton,
  onClickRemoveButton,
  onToggleBlock,
  onToggleAdmin,
}: Props) {
  const userIsBlocked = Boolean(userAccessBlockedAt);

  const onChangeToggleBlock = (value: boolean) => {
    console.log('onChangeToggleBlock', id, value);
    onToggleBlock(id, value);
  };

  const onChangeToggleAdmin = (value: boolean) => {
    onToggleAdmin(id, value);
  };

  const handleClickEditButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton && onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton && onClickRemoveButton(id);
  };

  return (
    <TableBodyRow>
      <TableBodyColumn className="font-bold">{userName}</TableBodyColumn>
      <TableBodyColumn>{userEmail}</TableBodyColumn>
      <TableBodyColumn className="">
        <ToggleButton
          value={userIsAdmin}
          disabled={disableAdminToggle}
          label="Usuário é administrador?"
          onChange={onChangeToggleAdmin}
        />
      </TableBodyColumn>
      <TableBodyColumn className="">
        <ToggleButton
          value={userIsBlocked}
          label="Usuário está bloqueado?"
          onChange={onChangeToggleBlock}
        />
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
