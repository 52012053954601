import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useAuth } from 'auth/contexts/Auth';
import { AUTHENTICATE } from 'auth/graphql/authenticateQuery';
import EmailField from './EmailField';
import PasswordField from './PasswordField';

type SignInUser = {
  userEmail?: string;
  userPassword?: string;
};

export default function LoginForm() {
  const { showLoading, closeLoading } = useLoading();
  const { storeCredentials, token } = useAuth();
  const [user, setUser] = React.useState<SignInUser>();
  const [authenticate, { loading, data, error }] = useLazyQuery(AUTHENTICATE);

  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!!user?.userEmail && !!user?.userPassword) {
      authenticate({
        variables: {
          userEmail: user.userEmail,
          userPassword: user.userPassword,
        },
      });
    }
  };

  React.useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }

    if (!token && !!data?.authenticate?.userToken) {
      storeCredentials({
        token: data.authenticate.userToken,
        user: data.authenticate.user,
      });
    }

    closeLoading();
  }, [closeLoading, data, loading, showLoading, storeCredentials, token]);

  if (!!token) return <Redirect to="/dashboard" />;

  return (
    <form
      onSubmit={handleSubmitForm}
      className="flex flex-col gap-y-5"
      data-testid="login-form"
    >
      <EmailField
        required
        autoComplete="email"
        error={error ? 'Email ou senha inválido!' : ''}
        defaultValue={user?.userEmail}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setUser({ ...user, userEmail: event.target.value })
        }
      />

      <PasswordField
        required
        autoComplete="current-password"
        error={error ? 'Email ou senha inválido!' : ''}
        defaultValue={user?.userPassword}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setUser({ ...user, userPassword: event.target.value })
        }
      />

      <div className="text-center">
        <Button className="uppercase" type="submit">
          Login
        </Button>
      </div>
    </form>
  );
}
