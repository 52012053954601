type Props = {
  title: string;
};

export default function DashboardMainHeaderTitle({ title }: Props) {
  return (
    <header className="pt-6">
      <div className="max-w-7xl mx-auto px-4 flex items-center gap-x-3">
        <h1 className="text-2xl font-bold text-gray-dark-b6">{title}</h1>
      </div>
    </header>
  );
}
