type Props = {
  children: React.ReactNode;
};

export default function TableHeadColumn({ children }: Props) {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-left font-medium text-gray-dark-6b uppercase tracking-wider"
    >
      {children}
    </th>
  );
}
