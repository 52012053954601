import React from 'react';
import CheckboxInput from 'ui/components/form/CheckboxInput';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      inputLabel: string;
      label?: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

// @TODO: map options from props to create checkboxes

export const CheckboxField = React.forwardRef<HTMLInputElement, Props>(
  ({ className, wrapperClassName, inputLabel, label, ...props }, ref) => {
    return (
      <fieldset className={wrapperClassName}>
        <span className="block text-sm font-medium text-gray-dark-b6 mb-3">
          {label}
        </span>
        <CheckboxInput label={inputLabel} ref={ref} {...props} />
      </fieldset>
    );
  }
);

export default CheckboxField;
