import { MdAdd } from 'react-icons/md';
import { Button } from 'ui';

type Props = {
  onClick: ((e: React.SyntheticEvent<HTMLButtonElement>) => void) | undefined;
  label: string;
};

export default function AddButton({ onClick, label }: Props) {
  return (
    <Button size="none" className="p-2" onClick={onClick}>
      <MdAdd className="h-4 w-3.h-4" aria-hidden="true" />
      <span>{label}</span>
    </Button>
  );
}
