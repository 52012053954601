import { Link } from 'react-router-dom';
import { HiLogout } from 'react-icons/hi';

export default function LogoutLink() {
  return (
    <Link
      to="/sign-in"
      className="hover:bg-gray-dark-14 group p-3 mx-2 mb-3 rounded-md flex flex-col items-center text-center text-xs font-medium"
    >
      <HiLogout className="h-4 w-4" aria-hidden="true" />
      <span></span>
      <span className="mt-2">Sair</span>
    </Link>
  );
}
