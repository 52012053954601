import { Switch, Route } from 'react-router-dom';

import { LoginPage, NewPasswordPage } from 'auth/pages/login';
import {
  ListInternalUsersPage,
  CreateInternalUsersPage,
  EditInternalUsersPage,
} from 'dashboard/pages/internal-users';
import { ListPowerDistributionUnitsPage } from 'powerDistributionUnits/pages';
import { Dashboard } from 'dashboard/components/dashboard';

const Routes = () => (
  <Switch>
    <Route path="/sign-in/new-password" component={NewPasswordPage} />
    <Route
      path="/dashboard/internal-users/create"
      component={CreateInternalUsersPage}
    />
    <Route
      path="/dashboard/internal-users/:id/edit"
      component={EditInternalUsersPage}
    />
    <Route path="/dashboard/internal-users" component={ListInternalUsersPage} />
    <Route
      path="/dashboard/power-distribution-units"
      component={ListPowerDistributionUnitsPage}
    />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/" component={LoginPage} />
  </Switch>
);

export default Routes;
