import { gql } from '@apollo/client';

export const POWER_DISTRIBUTION_UNIT_LIST_QUERY = gql`
  query powerDistributionUnits($after: String, $before: String, $pageSize: Int) {
    powerDistributionUnits(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        powerDistributionUnitCountryState
        powerDistributionUnitDocumentId
        powerDistributionUnitLegalName
      }
    }
  }
`;
