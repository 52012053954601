import { gql } from '@apollo/client';
import { InternalUser } from 'dashboard/models/internal-user';

export interface InternalUserCreateMutationInput {
  userName: string;
  userEmail: string;
  userIsAdmin: boolean;
}

export interface InternalUserCreateMutationVariables {
  internalUserCreateInput: InternalUserCreateMutationInput;
}

export interface InternalUserCreated {
  internalUserCreate: InternalUser;
}

export const INTERNAL_USER_CREATE_MUTATION = gql`
  mutation ($internalUserCreateInput: InternalUserCreateInput!) {
    internalUserCreate(input: $internalUserCreateInput) {
      userAccessBlockedAt
      userEmail
      userName
      userIsAdmin
    }
  }
`;
