import classNames from 'classnames';
import React from 'react';
import { TextInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      id: string;
      label: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  ({ className, wrapperClassName, label, id, ...props }, ref) => {
    return (
      <fieldset className={wrapperClassName}>
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-dark-b6 mb-3"
        >
          {label}
        </label>
        <TextInput
          id={id}
          className={classNames(
            'mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark-6b bg-gray-dark400 shadow-none rounded-md',
            className
          )}
          ref={ref}
          {...props}
        />
      </fieldset>
    );
  }
);

export default TextField;
