import { camelCase } from 'lodash';
import { ApolloError } from '@apollo/client';
import { ErrorOption } from 'react-hook-form';
// import { TFunction } from 'i18next';

export const setFormError = (
  error: ApolloError,
  setError: (
    field: string,
    error: ErrorOption,
    options: { shouldFocus: true }
  ) => void
  // t: TFunction
) => {
  let messages: string = '';
  return error.message.split('\n').forEach((errorMessage: string) => {
    const errorMessageSlices = errorMessage.split(' ');
    messages = messages.concat(' ').concat(errorMessageSlices.splice(1).join(' '));

    setError(
      camelCase(errorMessageSlices[0]),
      {
        type: 'manual',
        message: messages,
      },
      { shouldFocus: true }
    );
  });
};

export const FORM_ERRORS_MAP: Record<string, string> = {
  required: 'Campo obrigatório!',
};
