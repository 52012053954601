import React, { createContext, useState, useContext, useCallback } from 'react';

interface User {
  userName: string;
  userEmail: string;
}

interface AuthState {
  token: null | string;
  user: User;
}

type AuthContextData = {
  storeCredentials(credentials: AuthState): void;
  resetCredentials(): void;
} & AuthState;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const USER_KEY = '@nex-intranex-app:user';
const TOKEN_KEY = '@nex-intranex-app:token';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    const user = localStorage.getItem(USER_KEY) || '{}';

    return { token, user: JSON.parse(user) };
  });

  const storeCredentials = useCallback(({ token, user }: AuthState) => {
    localStorage.setItem(TOKEN_KEY, `${token}`);
    localStorage.setItem(USER_KEY, JSON.stringify(user));

    setData({ token, user });
  }, []);

  const resetCredentials = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  };

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        resetCredentials,
        storeCredentials,
        user: data.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('AuthProvider is required!');
  }

  return context;
}

export { AuthProvider, useAuth };
