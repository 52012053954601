import { BrowserRouter } from 'react-router-dom';

import Routes from 'app/routes';
import client from 'graphql/apollo/client';

import { ApolloProvider } from '@apollo/client';
import { ConfirmProvider } from 'ui/contexts/overlay/Confirm';
import { LoadingProvider } from 'ui/contexts/overlay/Loading';
import { ToastContextProvider } from 'ui/contexts/overlay/Toast';
import { useAuth } from 'auth/contexts/Auth';

export default function App() {
  const { token } = useAuth();

  return (
    <BrowserRouter>
      <ApolloProvider client={client(token)}>
        <ToastContextProvider>
          <LoadingProvider>
            <ConfirmProvider>
              <Routes />
            </ConfirmProvider>
          </LoadingProvider>
        </ToastContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
