import { FormContainer } from 'ui';
import CheckboxField from 'dashboard/components/form/CheckboxField';
import EmailField from 'dashboard/components/form/EmailField';
import TextField from 'dashboard/components/form/TextField';
import { FieldError, UseFormRegister } from 'react-hook-form';

type Field = 'userName' | 'userEmail' | 'userIsAdmin';

export const FORM_FIELDS: Field[] = ['userName', 'userEmail', 'userIsAdmin'];
export type FormFields = {
  userName: string;
  userEmail: string;
  userIsAdmin: boolean;
};

export type FormErrors = {
  userName?: FieldError | undefined;
  userEmail?: FieldError | undefined;
  userIsAdmin?: FieldError | undefined;
};

export default function UserFormFields({
  disable,
  errors,
  register,
}: {
  disable?: {
    userEmail?: boolean;
  };
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
}) {
  return (
    <FormContainer>
      <div className="grid grid-cols-6 gap-6">
        <CheckboxField
          error={errors.userIsAdmin?.message}
          wrapperClassName="col-span-6"
          label="O usuário é admin?"
          {...register('userIsAdmin', { required: false })}
          inputLabel="Admin"
        />

        <TextField
          error={errors.userName?.message}
          wrapperClassName="col-span-6 sm:col-span-3"
          label="Nome"
          id="userName"
          {...register('userName', { required: true })}
          placeholder="Ex.: João da Silva"
        />

        <EmailField
          disabled={disable?.userEmail}
          error={errors.userEmail?.message}
          wrapperClassName="col-span-6 sm:col-span-3"
          label="Email"
          {...register('userEmail', { required: true })}
          placeholder="exemplo@email.com"
        />
      </div>
    </FormContainer>
  );
}
