import { gql } from '@apollo/client';
import { InternalUser } from 'dashboard/models/internal-user';

export interface InternalUserUpdateMutationInput {
  id: number;
  userName: string;
  userIsAdmin: boolean;
}

export interface InternalUserUpdateMutationVariables {
  internalUserUpdateInput: InternalUserUpdateMutationInput;
}

export interface InternalUserUpdated {
  internalUserUpdate: InternalUser;
}

export const INTERNAL_USER_UPDATE_MUTATION = gql`
  mutation internalUserUpdate($internalUserUpdateInput: InternalUserUpdateInput!) {
    internalUserUpdate(input: $internalUserUpdateInput) {
      id
      userName
      userEmail
      userAccessBlockedAt
      userIsAdmin
    }
  }
`;
