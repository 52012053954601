import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

export type EmptyMessage = {
  message: string;
};

type Token = string | null;

const authLink = (token: Token) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
};

export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URI_GRAPHQL,
});

const client = (token: Token) =>
  new ApolloClient<NormalizedCacheObject>({
    link: authLink(token).concat(httpLink),
    cache: new InMemoryCache(),
  });

export default client;
