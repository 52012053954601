import React from 'react';
import classNames from 'classnames';

const ButtonClassnames = {
  variants: {
    primary:
      'bg-primary hover:bg-white hover:text-primary border border-primary text-xs font-bold text-gray-dark500 focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-50',
    alertConfirm:
      'hover:bg-white border text-xs font-bold focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-50 text-white bg-red-a1 border-red-a1 hover:text-red-a1',
    alertCancel:
      'hover:bg-white border text-xs font-bold focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-50 text-gray-dark-b6 bg-gray-dark300 border-gray-dark300 hover:text-red-a1',
    link: 'outline-none bg-transparent hover:text-primary focus:outline-none group text-white text-xs font-bold',
    icon: 'outline-none hover:text-primary focus:text-primary focus:outline-none',
    circular:
      'inline-flex items-center border border-primary shadow-sm text-gray-dark-11 bg-primary hover:bg-primary-dark200 focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-50',
    outline:
      'outline-none border border-gray-dark-b6 text-gray-dark-b6 hover:bg-primary hover:border-primary hover:text-gray-background',
  },
  sizes: {
    sm: 'py-3 px-6',
    md: 'py-3 px-11',
    none: '',
  },
  rounded: {
    md: 'rounded',
    lg: 'rounded-lg',
    circular: 'rounded-full',
  },
};

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    ref?: React.Ref<HTMLButtonElement>;
    variant?: keyof typeof ButtonClassnames.variants;
    size?: keyof typeof ButtonClassnames.sizes;
    rounded?: keyof typeof ButtonClassnames.rounded;
  },
  HTMLButtonElement
>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      size = 'md',
      rounded = 'md',
      type = 'button',
      className = '',
      children,
      ...props
    },
    ref
  ) => {
    const buttonClasses = classNames(
      'inline-flex items-center justify-center gap-x-2',
      ButtonClassnames.variants[variant],
      ButtonClassnames.sizes[size],
      ButtonClassnames.rounded[rounded],
      className
    );

    return (
      <button type={type} className={buttonClasses} ref={ref} {...props}>
        {children}
      </button>
    );
  }
);

export default Button;
