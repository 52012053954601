import React from 'react';

export const FormContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-10 sm:mt-0">
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="mt-5 md:mt-0 md:col-span-2">{children}</div>
    </div>
  </div>
);

export default FormContainer;
