import { Button } from 'ui';
import { MdDelete } from 'react-icons/md';
import { Props } from './Button';

export const DeleteButton = (props: Props) => (
  <Button
    size="sm"
    {...props}
    className="text-red-a1 border-red-a1 bg-transparent hover:bg-red-a1 hover:text-white focus:ring-0 focus:ring-transparent"
  >
    <MdDelete className="flex-shrink-0 h-5 w-5" />
    Deletar
  </Button>
);

export default DeleteButton;
