import Sidebar from 'dashboard/components/sidebar/Sidebar';

type Props = {
  children: React.ReactNode;
  dashboardHeader?: React.ReactNode;
  dashboardMainHeaderTitle?: React.ReactNode;
};

export default function Dashboard({
  children,
  dashboardHeader,
  dashboardMainHeaderTitle,
}: Props) {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-dark-11 text-gray-dark-6b">
      <Sidebar />

      <section className="flex flex-col w-0 flex-1 overflow-hidden">
        {dashboardHeader}

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {dashboardMainHeaderTitle}
          <div className="max-w-7xl w-full mx-auto py-5 px-4">{children}</div>
        </main>
      </section>
    </div>
  );
}
