import React from 'react';
import { MdDone, MdInfoOutline } from 'react-icons/md';
import ReactModal from 'react-modal';
import { Button } from 'ui';
import { Variant } from 'ui/models/overlay';

type Props = {
  variant: Variant;
  onCloseModal: () => void;
  isOpen: boolean;
  contentMessage: string;
  contentLabel: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  onConfirm(): void;
  onCancel(): void;
};

const variants: Record<
  Variant,
  { icon: React.ReactNode; confirmButton: 'alertConfirm' | 'primary' }
> = {
  danger: {
    icon: <MdInfoOutline className="text-red-a1 text-2xl" />,
    confirmButton: 'alertConfirm',
  },
  primary: {
    icon: <MdDone className="text-primary text-2xl" />,
    confirmButton: 'primary',
  },
};

const Confirm = ({
  isOpen,
  onCloseModal,
  contentLabel,
  contentMessage,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  onConfirm,
  onCancel,
  variant,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      overlayElement={(overlayProps, contentElement) => (
        <div
          {...overlayProps}
          className="fixed inset-0 h-full w-full bg-black bg-opacity-70 flex items-center justify-center z-50"
          style={{}}
        >
          {contentElement}
        </div>
      )}
      contentElement={({ title, ...props }) => (
        <div {...props} className="flex items-center justify-center" style={{}}>
          <div className="bg-gray-background rounded-lg shadow-xl p-8 max-w-md flex">
            <div className="mr-5">
              <div className="bg-gray-dark-what p-2 rounded-full">
                {variants[variant].icon}
              </div>
            </div>
            <div>
              <h4 className="text-gray-dark-b6 font-medium text-lg">
                {contentLabel}
              </h4>
              <p className="text-gray-dark300 text-sm m-5 ml-0">{contentMessage}</p>
              <div className="flex flex-row-reverse">
                <Button
                  variant={variants[variant].confirmButton}
                  size="md"
                  onClick={onConfirm}
                >
                  Confirmar
                </Button>
                <Button
                  variant="alertCancel"
                  className="mr-2"
                  size="md"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Confirm;
