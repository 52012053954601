import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import type { SubMenuSidebar } from 'dashboard/models/navigation';

type Props = {
  icon: ReactNode;
  isCurrentPage: boolean;
  name: string;
  subtitle: string;
  submenu?: SubMenuSidebar[];
};

export default function NavigationItem({
  icon,
  isCurrentPage,
  name,
  subtitle,
  submenu,
}: Props) {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Menu>
      {({ open }) => (
        <>
          <div ref={setTargetElement} className="rounded-md shadow-sm">
            <Menu.Button
              aria-current={isCurrentPage ? 'page' : undefined}
              className={classNames(
                'hover:bg-gray-dark-14 group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium',
                {
                  'bg-gray-dark-14': open,
                }
              )}
            >
              {icon}
              <span className="mt-2">{name}</span>
            </Menu.Button>
          </div>

          <Portal>
            <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  static
                  className="w-56 origin-top-right text-gray-dark-6b bg-gray-dark-14 border border-gray-dark-14 rounded-md  shadow-lg outline-none"
                >
                  <div className="px-4 py-3">
                    <p className="text-xs font-semibold">{subtitle}</p>
                  </div>

                  {submenu && (
                    <div className="py-1">
                      {submenu.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.to}
                              className={classNames(
                                'flex items-center w-full px-4 py-2 text-xs font-semibold leading-5 text-left',
                                active ? 'text-gray-dark-b6' : 'text-gray-dark-6b'
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 mr-1"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                </Menu.Items>
                <div ref={setArrowElement} style={styles.arrow} className="-left-1">
                  <div className="w-2 h-2 bg-gray-dark-14 rotate-45" />
                </div>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
}
