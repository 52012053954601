import { Button } from 'ui';
import { MdDelete } from 'react-icons/md';

type Props = {
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
};

export default function RemoveButton({ onClick }: Props) {
  return (
    <Button
      variant="icon"
      size="none"
      className="text-gray-dark400 hover:text-gray-dark-6b focus:text-gray-dark-b6"
      onClick={onClick}
    >
      <MdDelete className="w-5 h-5" aria-hidden="true" />
    </Button>
  );
}
