import { gql } from '@apollo/client';

export const INTERNAL_USER_LIST_QUERY = gql`
  query internalUsers($after: String, $before: String, $pageSize: Int) {
    internalUsers(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        userEmail
        userName
        userAccessBlockedAt
        userIsAdmin
      }
    }
  }
`;
